import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  OnInit,
  AfterViewInit,
  HostListener,
  OnDestroy,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { AngularSignaturePadModule, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { FormsModule } from '@angular/forms';
import html2canvas from 'html2canvas';
import { SharedModule } from '../../shared/shared.module';

export enum SignatureMode {
  Draw = 'draw',
  Type = 'type',
}

@Component({
  selector: 'app-signature-popup',
  templateUrl: './signature-popup.component.html',
  styleUrls: ['./signature-popup.component.scss'],
  standalone: true,
  imports: [AngularSignaturePadModule, FormsModule, SharedModule],
})
export class SignaturePopupComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('signature', { static: true }) public signaturePad: SignaturePadComponent;
  @ViewChild('signatureCanvas', { static: false }) signatureCanvas: ElementRef;
  @Input() data: { id: string; password: string; shouldFixedPage: true };
  @Output() closed = new EventEmitter();
  email = '';
  disableBtn = true;
  signatureText = '';
  mode: SignatureMode = SignatureMode.Draw;
  SignatureMode = SignatureMode;

  @HostListener('window:resize')
  onResize(): void {
    this.clear();
    this.initSignaturePad();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(): void {
    this.initSignaturePad();
  }

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.initSignaturePad();
    if (this.data?.shouldFixedPage) {
      this.fixedPage(true);
    }
  }

  ngOnDestroy(): void {
    this.fixedPage(false);
  }

  ngAfterViewInit(): void {
    this.disableBtn = this.signaturePad.isEmpty();
  }

  drawStart(): void {
    this.disableBtn = false;
  }

  validateSignatureFullName(event: KeyboardEvent) {
    if (this.signatureText.trim() === '' && event.key === ' ') {
      event.preventDefault();
    }
  }

  validatePaste(event: ClipboardEvent): void {
    const clipboardData = (event.clipboardData || window['clipboardData']).getData('text');
    if (!clipboardData.trim()) {
      event.preventDefault();
    }
  }

  save(): void {
    if (this.mode === SignatureMode.Draw) {
      this.closed.emit(this.signaturePad.toDataURL('png'));
      this.disableBtn = false;
    }
    if (this.mode === SignatureMode.Type) {
      if (!this.signatureCanvas) return;
      const canvasElement = this.signatureCanvas.nativeElement;
      const boundingRect = canvasElement.getBoundingClientRect();
      html2canvas(canvasElement, {
        backgroundColor: null,
        scale: 3,
        width: boundingRect.width + 10,
        height: boundingRect.height + 15,
        useCORS: true,
      }).then((canvas: HTMLCanvasElement) => {
        this.closed.emit(canvas.toDataURL('image/png'));
        this.disableBtn = true;
      });
    }
  }

  clear(): void {
    this.disableBtn = true;
    this.signatureText = '';
    this.signaturePad.clear();
    if (this.mode === SignatureMode.Type) {
      this.disableBtn = false;
    }
  }

  changeTab(mode: SignatureMode): void {
    this.clear();
    this.mode = mode;
    if (this.mode === SignatureMode.Type) {
      this.disableBtn = false;
    }
  }

  initSignaturePad(): void {
    const canvas: HTMLCanvasElement = document.querySelector('canvas');
    if (!canvas) {
      return;
    }
    if (window.innerWidth <= 374) {
      canvas.setAttribute('height', '160px');
      canvas.setAttribute('width', '278px');
    } else if (window.innerWidth <= 425) {
      canvas.setAttribute('height', '180px');
      canvas.setAttribute('width', '315px');
    } else if (window.innerWidth < 650) {
      canvas.setAttribute('height', '200px');
      canvas.setAttribute('width', '360px');
    } else {
      canvas.setAttribute('height', '262px');
      canvas.setAttribute('width', '483px');
    }
  }

  validateSign(): boolean {
    const arrayPointSign = this.signaturePad.toData();
    const len: number = arrayPointSign?.reduce((acc: number, value): number => {
      return value.points.length > 3 ? acc + value.points.length : acc;
    }, 0);
    return len > 20;
  }

  private fixedPage(enabled: boolean): void {
    const el: Element = document.querySelector('app-dashboard .dashboard');
    const mainWrapper: Element = document.querySelector('.main-wrapper');
    const body: HTMLBodyElement = document.querySelector('body');
    if (enabled) {
      if (el) {
        this.renderer.addClass(el, 'fixed');
      }
      if (mainWrapper) {
        this.renderer.addClass(mainWrapper, 'fixed');
      }
      this.renderer.addClass(body, 'scrollDisabled');
    } else {
      if (el) {
        this.renderer.removeClass(el, 'fixed');
      }
      if (mainWrapper) {
        this.renderer.removeClass(mainWrapper, 'fixed');
      }
      this.renderer.removeClass(body, 'scrollDisabled');
    }
  }
}
