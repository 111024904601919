<div class="signature-tabs">
  <button
    (click)="changeTab(SignatureMode.Draw)"
    [class.active]="mode === SignatureMode.Draw">
    Draw
  </button>
  <button
    (click)="changeTab(SignatureMode.Type)"
    [class.active]="mode === SignatureMode.Type">
    Type
  </button>
</div>
<div [hidden]="mode === SignatureMode.Type" class="draw-mode">
  <signature-pad
    (drawStart)="drawStart()"
    #signature
  ></signature-pad>
  <div class="signature-utility-text">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.00049 7.5C7.00049 7.22386 7.22435 7 7.50049 7C7.7657 7 8.02006 7.10536 8.20759 7.29289C8.39513 7.48043 8.50049 7.73478 8.50049 8V10.5C8.77663 10.5 9.00049 10.7239 9.00049 11C9.00049 11.2761 8.77663 11.5 8.50049 11.5C8.23527 11.5 7.98092 11.3946 7.79338 11.2071C7.60584 11.0196 7.50049 10.7652 7.50049 10.5V8C7.22435 8 7.00049 7.77614 7.00049 7.5Z"
        fill="#6469B7"/>
      <path
        d="M7.75049 5.875C8.09567 5.875 8.37549 5.59518 8.37549 5.25C8.37549 4.90482 8.09567 4.625 7.75049 4.625C7.40531 4.625 7.12549 4.90482 7.12549 5.25C7.12549 5.59518 7.40531 5.875 7.75049 5.875Z"
        fill="#6469B7"/>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00049 2.5C4.96292 2.5 2.50049 4.96243 2.50049 8C2.50049 11.0376 4.96292 13.5 8.00049 13.5C11.0381 13.5 13.5005 11.0376 13.5005 8C13.5005 4.96243 11.0381 2.5 8.00049 2.5ZM1.50049 8C1.50049 4.41015 4.41064 1.5 8.00049 1.5C11.5903 1.5 14.5005 4.41015 14.5005 8C14.5005 11.5899 11.5903 14.5 8.00049 14.5C4.41064 14.5 1.50049 11.5899 1.50049 8Z"
        fill="#6469B7"/>
    </svg>
    <span>Should not be signed with a dot or single line. Cross mark is acceptable.</span>
  </div>
</div>
<div [hidden]="mode === SignatureMode.Draw" class="type-mode">
  <label class="label">
    <span>Full name</span>
    <input [(ngModel)]="signatureText" (keydown)="validateSignatureFullName($event)" (paste)="validatePaste($event)" [appMaxLength]="50" type="text"/>
  </label>
  <div class="label">
    <span>Review your signature</span>
    <div class="signature-preview">
      <span #signatureCanvas>{{ signatureText }}</span>
    </div>
  </div>
</div>
<div class="warning-text">
  By clicking Save, I agree that the signature will be the electronic representation of my signature for all purposes,
  just the same as a pen-and-paper signature.
</div>
<div class="btn-wrapper">
  <button
    (click)="disableBtn = true; save()"
    [disabled]="disableBtn || (mode === SignatureMode.Draw) ? !validateSign() : !signatureText"
    class="btn violet"
    data-e2e="save-signature">
    Save
  </button>
  <button (click)="clear()" class="clear-all" data-e2e="clear-signature">Clear</button>
</div>
